import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';

import {
  AdminOrganization
} from 'src/redux/openapi';
import {
  formatDateRange
} from 'src/shared/utils';

import {
  ActionHandler
} from '../../types';
import {
  EditMenu
} from '../EditMenu';
import * as Style from '../OrgManagementTable.styles';

const orgColumnHelper = createColumnHelper<AdminOrganization>();

export const getOrganizationCols = (
  onAction: ActionHandler,
  language: string,
) => {
  const columns = [
    orgColumnHelper.accessor(
      'name',
      {
        header: t('organisation.organisationName'),
        footer: (props) => props.column.id,
        size: 338,
        minSize: 128,
        cell: (info) => (
          <Style.NameCellSpan>{info.getValue()}</Style.NameCellSpan>
        ),
      }
    ),
    orgColumnHelper.accessor(
      'admins',
      {
        id: 'admin',
        header: t('common.admin'),
        footer: (props) => props.column.id,
        size: 338,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const superAdmin = adminsInfo?.find(
            ({
              role
            }) => role === 'SUPERADMIN',
          );

          return superAdmin?.email || '';
        },
      }
    ),
    orgColumnHelper.accessor(
      'admins',
      {
        id: 'subAdmins',
        header: t('common.subAdmins'),
        footer: (props) => props.column.id,
        size: 338,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const admins = adminsInfo
            ?.filter(({
              role
            }) => role === 'ADMIN')
            .map((admin) => admin.email)
            .join(', ');

          return admins;
        },
      }
    ),
    orgColumnHelper.accessor(
      'groupsCount',
      {
        header: () => (
          <Style.CenteredCellText>{t('common.groups')}</Style.CenteredCellText>
        ),
        footer: (props) => props.column.id,
        size: 72,
        cell: (info) => (
          <Style.RightAlignedCellText>
            {info.getValue()}
          </Style.RightAlignedCellText>
        ),
      }
    ),
    orgColumnHelper.accessor(
      'membersCount',
      {
        header: () => (
          <Style.CenteredCellText>
            {t('common.usersAmount')}
          </Style.CenteredCellText>
        ),
        footer: (props) => props.column.id,
        size: 108,
        minSize: 108,
        cell: (info) => (
          <Style.RightAlignedCellText>
            {info.getValue()}
          </Style.RightAlignedCellText>
        ),
      }
    ),
    orgColumnHelper.accessor(
      'createdAt',
      {
        header: t('filters.createDate'),
        footer: (props) => props.column.id,
        minSize: 132,
        cell: (info) => formatDateRange({
          start: info.getValue(),
          language,
        }),
      }
    ),
    orgColumnHelper.accessor(
      'id',
      {
        id: 'menu-column',
        header: '',
        footer: (props) => props.column.id,
        size: 48,
        cell: (info) => (
          <EditMenu
            type="organization"
            id={info.getValue()}
            onAction={onAction}
          />
        ),
      }
    ),
  ];

  return columns;
};
