import React, {
  FC, useEffect, useMemo
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  EmptyResultsMessage,
  Spinner,
  successfulToast,
  TableContent,
  TableFooter,
  usePaginationSettings,
} from 'src/shared/components';
import {
  AdminUpdateOrganizationBody,
  useGetAdminOrganizationQuery,
  useGetAdminOrganizationsQuery,
  usePutAdminOrganizationMutation,
} from 'src/redux/openapi';
import {
  CreateOrganizationModal,
  DeleteOrganizationModal,
} from 'src/features/Modals';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  ManagementTableRow
} from '../ManagementTableRow';
import {
  EditMenuActions
} from '../../types';

import {
  getOrganizationCols
} from './utils';

interface OrganisationsContentProps {
  searchQuery: string;
  onAction: (id: string, action: EditMenuActions) => void;
  removeAction: () => void;
  currentAction: EditMenuActions | null;
  idToModify: string | null;
}

export const OrganisationsContent: FC<OrganisationsContentProps> = ({
  searchQuery,
  onAction,
  currentAction,
  idToModify,
  removeAction,
}) => {
  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  const {
    data: organizationsData,
    refetch: refetchOrganizations,
    isLoading,
  } = useGetAdminOrganizationsQuery(
    {
      filters: {
        query: searchQuery,
        status: 'ACCEPTED',
      },
      limit: pageSize,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalOrganizations, organizations
  } = useMemo(
    () => organizationsData?.data || {
      organizations: [],
      total: 0,
    },
    [organizationsData],
  );

  useEffect(
    () => {
      if (!organizations.length && currentPage !== 1) {
        setCurrentPage((prev) => prev - 1);
      }
    },
    [organizations.length]
  );

  const {
    data: orgToEditData
  } = useGetAdminOrganizationQuery(
    {
      id: idToModify || '',
    },
    {
      skip: currentAction !== EditMenuActions.EditOrganisation || !idToModify,
      refetchOnMountOrArgChange: true,
    },
  );

  const organizationToEdit = useMemo(
    () => {
      const organization = orgToEditData?.data.organization;

      return {
        ...organization,
        admins:
        organization?.users.map(({
          role, user
        }) => ({
          role,
          email: user.email,
        })) || [],
      };
    },
    [orgToEditData?.data.organization]
  );

  const [saveEditResult] = usePutAdminOrganizationMutation();

  const onEditEnd = async (
    updatedOrganization?: AdminUpdateOrganizationBody,
  ) => {
    if (!updatedOrganization) {
      removeAction();

      return;
    }

    try {
      const response = await saveEditResult({
        id: idToModify || '',
        adminUpdateOrganizationByIdBodySchema: {
          ...updatedOrganization,
        },
      }).unwrap();

      successfulToast(response.message);
      removeAction();
      refetchOrganizations();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const organizationColumns = useMemo(
    () => getOrganizationCols(
      onAction,
      language
    ),
    [onAction, language],
  );

  const orgLabel = t('organisation.organisations');

  if (isLoading) {
    return <Spinner withLoadingText />;
  }

  if (!organizations.length) {
    return (
      <EmptyResultsMessage
        results={orgLabel.toLowerCase()}
        withSearchQuery={!!searchQuery}
      />
    );
  }

  return (
    <>
      <TableContent
        data={organizations}
        columns={organizationColumns}
        renderRow={(row, index) => (
          <ManagementTableRow
            row={row}
            key={row.original.id}
            index={index}
          />
        )}
      />

      <TableFooter
        totalCount={totalOrganizations}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={orgLabel}
      />

      <DeleteOrganizationModal
        isOpen={currentAction === EditMenuActions.DeleteOrg}
        closeModal={removeAction}
        id={idToModify}
        onSuccessDelete={refetchOrganizations}
      />

      <CreateOrganizationModal
        isOpen={currentAction === EditMenuActions.EditOrganisation}
        onClose={removeAction}
        isEditing
        name={organizationToEdit?.name}
        admins={organizationToEdit.admins}
        orgDomain={organizationToEdit?.domain}
        customCreateMethod={onEditEnd}
      />
    </>
  );
};
