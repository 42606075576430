import {
  emptySplitApi as api
} from '../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAdminQuestionsActive: build.query<
    GetApiAdminQuestionsActiveApiResponse,
    GetApiAdminQuestionsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/questions/active`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          withOrganization: queryArg.withOrganization,
          query: queryArg.query,
        },
      }),
      providesTags: ['Scoring'],
    }),
    getApiAdminQuestionsScored: build.query<
    GetApiAdminQuestionsScoredApiResponse,
    GetApiAdminQuestionsScoredApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/questions/scored`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          withOrganization: queryArg.withOrganization,
          withFeedback: queryArg.withFeedback,
          query: queryArg.query,
        },
      }),
      providesTags: ['Scoring'],
    }),
    getApiAdminQuestionsDeactivated: build.query<
    GetApiAdminQuestionsDeactivatedApiResponse,
    GetApiAdminQuestionsDeactivatedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/questions/deactivated`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          withOrganization: queryArg.withOrganization,
          query: queryArg.query,
        },
      }),
      providesTags: ['Scoring'],
    }),
    getApiAdminQuestionsScoredByIdPostEstimationDownload: build.mutation<
    GetApiAdminQuestionsScoredByIdPostEstimationDownloadApiResponse,
    GetApiAdminQuestionsScoredByIdPostEstimationDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/questions/scored/${queryArg.id}/post-estimation/download`,
        method: 'GET',
        responseHandler: (response) => {
          if (response.ok) {
            return response.blob();
          }

          throw new Error();
        },
      }),
    }),
    getApiAdminQuestionsRequests: build.query<
    GetApiAdminQuestionsRequestsApiResponse,
    GetApiAdminQuestionsRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/questions/requests`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          query: queryArg.query,
        },
      }),
      providesTags: ['SetRequest'],
    }),
    postApiAdminQuestionsRequestsByIdApproval: build.mutation<
    PostApiAdminQuestionsRequestsByIdApprovalApiResponse,
    PostApiAdminQuestionsRequestsByIdApprovalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/questions/requests/${queryArg.id}/approval`,
        method: 'POST',
      }),
      invalidatesTags: ['SetRequest'],
    }),
    postApiAdminQuestionsRequestsByIdDecline: build.mutation<
    PostApiAdminQuestionsRequestsByIdDeclineApiResponse,
    PostApiAdminQuestionsRequestsByIdDeclineApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/questions/requests/${queryArg.id}/decline`,
        method: 'POST',
        body: queryArg.rejectQuestionCreationRequestBodySchema,
      }),
      invalidatesTags: ['SetRequest'],
    }),
    postApiAdminQuestions: build.mutation<
    PostApiAdminQuestionsApiResponse,
    PostApiAdminQuestionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/questions`,
        method: 'POST',
        body: queryArg.createQuestionsBodySchema,
      }),
    }),
    postApiAdminQuestionsUploadsXlsx: build.mutation<
    PostApiAdminQuestionsUploadsXlsxApiResponse,
    PostApiAdminQuestionsUploadsXlsxApiArg
    >({
      query: () => ({
        url: `/api/admin/questions/uploads/xlsx`,
        method: 'POST',
      }),
    }),
    getApiAdminOrganizations: build.query<
    GetApiAdminOrganizationsApiResponse,
    GetApiAdminOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
      providesTags: ['Organization'],
    }),
    postApiAdminOrganizations: build.mutation<
    PostApiAdminOrganizationsApiResponse,
    PostApiAdminOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations`,
        method: 'POST',
        body: queryArg.createOrganizationBodySchema,
      }),
      invalidatesTags: ['Organization'],
    }),
    getApiAdminOrganizationsRequests: build.query<
    GetApiAdminOrganizationsRequestsApiResponse,
    GetApiAdminOrganizationsRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
    }),
    getApiAdminOrganizationsRequestsUnreadCount: build.query<
    GetApiAdminOrganizationsRequestsUnreadCountApiResponse,
    GetApiAdminOrganizationsRequestsUnreadCountApiArg
    >({
      query: () => ({
        url: `/api/admin/organizations/requests/unread/count`,
      }),
    }),
    patchApiAdminOrganizationsRequestsUnread: build.mutation<
    PatchApiAdminOrganizationsRequestsUnreadApiResponse,
    PatchApiAdminOrganizationsRequestsUnreadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/unread`,
        method: 'PATCH',
        body: queryArg.updateGroupOrganizatinoRequestReadStatusBodySchema,
      }),
    }),
    getApiAdminOrganizationsRequestsById: build.query<
    GetApiAdminOrganizationsRequestsByIdApiResponse,
    GetApiAdminOrganizationsRequestsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/${queryArg.id}`,
      }),
    }),
    deleteApiAdminOrganizationsRequestsById: build.mutation<
    DeleteApiAdminOrganizationsRequestsByIdApiResponse,
    DeleteApiAdminOrganizationsRequestsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiAdminOrganizationsById: build.query<
    GetApiAdminOrganizationsByIdApiResponse,
    GetApiAdminOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/${queryArg.id}`,
      }),
      providesTags: ['Organization'],
    }),
    putApiAdminOrganizationsById: build.mutation<
    PutApiAdminOrganizationsByIdApiResponse,
    PutApiAdminOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.adminUpdateOrganizationByIdBodySchema,
      }),
    }),
    putApiAdminOrganizationsByIdQuestionsRequestsAutoApproval: build.mutation<
    PutApiAdminOrganizationsByIdQuestionsRequestsAutoApprovalApiResponse,
    PutApiAdminOrganizationsByIdQuestionsRequestsAutoApprovalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/${queryArg.id}/questions/requests/auto-approval`,
        method: 'PUT',
        body: queryArg.updateOrganizationQuestionAutoApprovalBodySchema,
      }),
    }),
    putApiAdminGroupsById: build.mutation<
    PutApiAdminGroupsByIdApiResponse,
    PutApiAdminGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/groups/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.adminUpdateGroupByIdBodySchema,
      }),
    }),
    getApiAdminGroupsById: build.query<
    GetApiAdminGroupsByIdApiResponse,
    GetApiAdminGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/groups/${queryArg.id}`,
      }),
    }),
    patchApiAdminOrganizationsRequestsByIdAccept: build.mutation<
    PatchApiAdminOrganizationsRequestsByIdAcceptApiResponse,
    PatchApiAdminOrganizationsRequestsByIdAcceptApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/${queryArg.id}/accept`,
        method: 'PATCH',
        body: queryArg.acceptGroupToOrganizationBodySchema,
      }),
    }),
    patchApiAdminOrganizationsRequestsByIdDecline: build.mutation<
    PatchApiAdminOrganizationsRequestsByIdDeclineApiResponse,
    PatchApiAdminOrganizationsRequestsByIdDeclineApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/${queryArg.id}/decline`,
        method: 'PATCH',
      }),
    }),
    getApiAdminGroups: build.query<
    GetApiAdminGroupsApiResponse,
    GetApiAdminGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/groups`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          query: queryArg.query,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiAdmin
};
export type GetApiAdminQuestionsActiveApiResponse =
  /** status 200 Default Response */ GetActiveQuestionsResponseSchema;
export type GetApiAdminQuestionsActiveApiArg = {
  page?: Page;
  limit?: Limit;
  withOrganization?: boolean;
  query?: string;
};
export type GetApiAdminQuestionsScoredApiResponse =
  /** status 200 Default Response */ GetDeactivatedQuestionsResponseSchema;
export type GetApiAdminQuestionsScoredApiArg = {
  page?: Page;
  limit?: Limit;
  withOrganization?: boolean;
  withFeedback?: boolean;
  query?: string;
};
export type GetApiAdminQuestionsDeactivatedApiResponse =
  /** status 200 Default Response */ GetDeactivatedQuestionsResponseSchema;
export type GetApiAdminQuestionsDeactivatedApiArg = {
  page?: Page;
  limit?: Limit;
  withOrganization?: boolean;
  query?: string;
};
export type GetApiAdminQuestionsScoredByIdPostEstimationDownloadApiResponse =
  unknown;
export type GetApiAdminQuestionsScoredByIdPostEstimationDownloadApiArg = {
  id: string;
};
export type GetApiAdminQuestionsRequestsApiResponse =
  /** status 200 Default Response */ FetchQuestionCreateRequestsResponseSchema;
export type GetApiAdminQuestionsRequestsApiArg = {
  page?: Page;
  limit?: Limit;
  query?: string;
};
export type PostApiAdminQuestionsRequestsByIdApprovalApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiAdminQuestionsRequestsByIdApprovalApiArg = {
  id: string;
};
export type PostApiAdminQuestionsRequestsByIdDeclineApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiAdminQuestionsRequestsByIdDeclineApiArg = {
  id: string;
  rejectQuestionCreationRequestBodySchema: RejectQuestionCreationRequestBodySchema;
};
export type PostApiAdminQuestionsApiResponse =
  /** status 201 Default Response */ MessageResponseSchema;
export type PostApiAdminQuestionsApiArg = {
  createQuestionsBodySchema: CreateQuestionsBodySchema;
};
export type PostApiAdminQuestionsUploadsXlsxApiResponse =
  /** status 200 Default Response */ GetParsedSetQuestionsResponseSchema;
export type PostApiAdminQuestionsUploadsXlsxApiArg = void;
export type GetApiAdminOrganizationsApiResponse =
  /** status 200 Default Response */ AdminOrgsResponseSchema;
export type GetApiAdminOrganizationsApiArg = {
  page?: number;
  limit?: number;
  filters?: {
    status?: 'ACCEPTED' | 'PENDING';
    query?: string;
  };
};
export type PostApiAdminOrganizationsApiResponse =
  /** status 201 Default Response */ CreateOrganizationResponseSchema;
export type PostApiAdminOrganizationsApiArg = {
  createOrganizationBodySchema: CreateOrganizationBodySchema;
};
export type GetApiAdminOrganizationsRequestsApiResponse =
  /** status 200 Default Response */ GroupOrganizationRequestsResponseSchema;
export type GetApiAdminOrganizationsRequestsApiArg = {
  page?: Page;
  limit?: Limit;
  filters?: {
    query?: string;
  };
};
export type GetApiAdminOrganizationsRequestsUnreadCountApiResponse =
  /** status 200 Default Response */ UnreadGroupOrganizationRequestCountResponseSchema;
export type GetApiAdminOrganizationsRequestsUnreadCountApiArg = void;
export type PatchApiAdminOrganizationsRequestsUnreadApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiAdminOrganizationsRequestsUnreadApiArg = {
  updateGroupOrganizatinoRequestReadStatusBodySchema: UpdateGroupOrganizatinoRequestReadStatusBodySchema;
};
export type GetApiAdminOrganizationsRequestsByIdApiResponse =
  /** status 200 Default Response */ GroupOrganizationRequestResponseSchema;
export type GetApiAdminOrganizationsRequestsByIdApiArg = {
  id: string;
};
export type DeleteApiAdminOrganizationsRequestsByIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiAdminOrganizationsRequestsByIdApiArg = {
  id: string;
};
export type GetApiAdminOrganizationsByIdApiResponse =
  /** status 200 Default Response */ AdminGetOrganizationByIdResponseSchema;
export type GetApiAdminOrganizationsByIdApiArg = {
  id: string;
};
export type PutApiAdminOrganizationsByIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiAdminOrganizationsByIdApiArg = {
  id: string;
  adminUpdateOrganizationByIdBodySchema: AdminUpdateOrganizationByIdBodySchema;
};
export type PutApiAdminOrganizationsByIdQuestionsRequestsAutoApprovalApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiAdminOrganizationsByIdQuestionsRequestsAutoApprovalApiArg = {
  id: string;
  updateOrganizationQuestionAutoApprovalBodySchema: UpdateOrganizationQuestionAutoApprovalBodySchema;
};
export type PutApiAdminGroupsByIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiAdminGroupsByIdApiArg = {
  id: string;
  adminUpdateGroupByIdBodySchema: AdminUpdateGroupByIdBodySchema;
};
export type GetApiAdminGroupsByIdApiResponse =
  /** status 200 Default Response */ AdminGetGroupByIdResponseSchema;
export type GetApiAdminGroupsByIdApiArg = {
  id: string;
};
export type PatchApiAdminOrganizationsRequestsByIdAcceptApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiAdminOrganizationsRequestsByIdAcceptApiArg = {
  id: string;
  acceptGroupToOrganizationBodySchema: AcceptGroupToOrganizationBodySchema;
};
export type PatchApiAdminOrganizationsRequestsByIdDeclineApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiAdminOrganizationsRequestsByIdDeclineApiArg = {
  id: string;
};
export type GetApiAdminGroupsApiResponse =
  /** status 200 Default Response */ GetUnaffiliatedGroupsResponseSchema;
export type GetApiAdminGroupsApiArg = {
  page?: Page;
  limit?: Limit;
  query?: string;
};
export type GetActiveQuestionsResponseSchema = {
  message: string;
  data: {
    questions: {
      id: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      startDate: string;
      endDate: string;
      participantsCount: number;
      commentsCount: number;
      updatesCount: number;
      globalEstimatesAvg: number;
      globalEstimatesChange: number;
      questionSet: {
        id: string;
        title: string;
      };
      organization: {
        id: string;
        name: string;
      } | null;
    }[];
    total: number;
  };
};
export type Page = number;
export type Limit = number;
export type GetDeactivatedQuestionsResponseSchema = {
  message: string;
  data: {
    questions: {
      id: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      type: 'SINGLE' | 'MULTIPLE' | 'SURVEY';
      startDate: string;
      endDate: string;
      result: boolean;
      participantsCount: number;
      commentsCount: number;
      updatesCount: number;
      globalEstimatesAvg: number;
      globalEstimatesChange: number;
      questionSet: {
        id: string;
        title: string;
      };
      organization: {
        id: string;
        name: string;
      } | null;
      hasFeedbacks: boolean;
    }[];
    total: number;
  };
};
export type FetchQuestionCreateRequestsResponseSchema = {
  message: string;
  data: {
    total: number;
    requests: {
      id: string;
      status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
      xlsxUrl: string;
      createdAt: string;
      isSubmitted: boolean;
      setTitle: string;
      setStartDate: string;
      setEndDate: string;
      organization: {
        id: string;
        name: string;
      };
    }[];
  };
};
export type MessageResponseSchema = {
  message: string;
};
export type RejectQuestionCreationRequestBodySchema = {
  message: string | null;
};
export type CreateQuestionsBodySchema = {
  relations:
  | {
    isGlobal: true;
    organizationIds: null;
    groupIds: null;
  }
  | {
    isGlobal: false;
    groupIds: string[];
    organizationIds: string[];
  };
  sets: {
    title: string;
    questions: {
      title: string;
      startDate: string;
      scoringStartDate: string;
      endDate: string;
      isMandatory: boolean;
      isPreferred: boolean;
      information: string | null;
      usefulLinks: string[];
      isScoringExempt: boolean;
    }[];
  }[];
};
export type GetParsedSetQuestionsResponseSchema = {
  message: string;
  data: {
    sets: {
      title: string;
      startDate: string;
      endDate: string;
      questions: {
        title: string;
        startDate: string;
        scoringStartDate: string;
        endDate: string;
        isMandatory: boolean;
        isPreferred: boolean;
        information: string | null;
        usefulLinks: string[];
        isScoringExempt: boolean;
      }[];
    }[];
  };
};
export type AdminOrgsResponseSchema = {
  message: string;
  data: {
    total: number;
    organizations: {
      id: string;
      name: string;
      isSetUp: boolean;
      createdAt: string;
      status: 'ACCEPTED' | 'PENDING';
      questionAutoApproval: boolean;
      groupsCount: number;
      membersCount: number;
      admins:
      | {
        id: string;
        name: string;
        displayName: string | null;
        role: 'SUPERADMIN' | 'ADMIN';
        email: string;
      }[]
      | null;
    }[];
  };
};
export type CreateOrganizationResponseSchema = {
  message: string;
  data: {
    organization: {
      name: string;
      id: string;
      status: 'ACCEPTED' | 'PENDING';
      createdAt: string;
      isSetUp: boolean;
    };
  };
};
export type CreateOrganizationBodySchema = {
  name: string;
  domain?: string;
  superadminEmail: string;
  adminEmails?: string[];
};
export type GroupOrganizationRequestsResponseSchema = {
  message: string;
  data: {
    groupRequests: {
      id: string;
      isRead: boolean;
      status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
      createdAt: string;
      updatedAt: string;
      description: string;
      group: {
        id: string;
        users: {
          role: 'SUPERADMIN' | 'ADMIN';
          user: {
            displayName: string | null;
            name: string;
            email: string;
            id: string;
          };
        }[];
        name: string;
        _count: {
          users: number;
        };
      };
      organization: {
        name: string;
        id: string;
      } | null;
    }[];
    total: number;
  };
};
export type UnreadGroupOrganizationRequestCountResponseSchema = {
  message: string;
  data: {
    total: number;
  };
};
export type UpdateGroupOrganizatinoRequestReadStatusBodySchema = {
  groupRequestIds: string[];
};
export type Items = {
  id: string;
  isRead: boolean;
  status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
  createdAt: string;
  updatedAt: string;
  description: string;
  group: {
    id: string;
    users: {
      role: 'SUPERADMIN' | 'ADMIN';
      user: {
        displayName: string | null;
        name: string;
        email: string;
        id: string;
      };
    }[];
    name: string;
    _count: {
      users: number;
    };
  };
  organization: {
    name: string;
    id: string;
  } | null;
};
export type GroupOrganizationRequestResponseSchema = {
  message: string;
  data: {
    groupRequest: Items;
  };
};
export type AdminGetOrganizationByIdResponseSchema = {
  message: string;
  data: {
    organization: {
      id: string;
      name: string;
      domain: string | null;
      logoUrl: string | null;
      questionAutoApproval: boolean;
      status: 'ACCEPTED' | 'PENDING';
      isSetUp: boolean;
      createdAt: string;
      updatedAt: string;
      users: {
        role: 'SUPERADMIN' | 'ADMIN';
        updatedAt: string;
        createdAt: string;
        email: string;
        user: {
          id: string;
          displayName: string | null;
          name: string;
          email: string;
        };
      }[];
    };
  };
};
export type AdminUpdateOrganizationByIdBodySchema = {
  name: string;
  domain?: string;
  superadminEmail: string;
  adminEmails?: string[];
};
export type UpdateOrganizationQuestionAutoApprovalBodySchema = {
  questionAutoApproval: boolean;
};
export type AdminUpdateGroupByIdBodySchema = {
  name: string;
  location?: string | null;
  superadminEmail: string;
  adminEmails?: string[];
};
export type AdminGetGroupByIdResponseSchema = {
  message: string;
  data: {
    group: {
      id: string;
      name: string;
      location: string | null;
      status: 'ACTIVATED' | 'DEACTIVATED';
      createdAt: string;
      updatedAt: string;
      users: {
        role: 'SUPERADMIN' | 'ADMIN' | 'USER';
        updatedAt: string;
        createdAt: string;
        email: string;
        user: {
          id: string;
          displayName: string | null;
          name: string;
          email: string;
        };
      }[];
    } | null;
  };
};
export type Name = string;
export type Domain = string;
export type SuperadminEmail = string;
export type AdminEmails = string[];
export type AcceptGroupToOrganizationBodySchema = {
  organization?: {
    name?: Name;
    domain?: Domain;
    superadminEmail?: SuperadminEmail;
    adminEmails?: AdminEmails;
  };
};
export type GetUnaffiliatedGroupsResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      status: 'ACTIVATED' | 'DEACTIVATED';
      name: string;
      location: string | null;
      createdAt: string;
      updatedAt: string;
      memberCount: number;
      admins:
      | {
        id: string;
        email: string;
        role: 'SUPERADMIN' | 'ADMIN';
        displayName: string | null;
        name: string;
      }[]
      | null;
    }[];
    total: number;
  };
};
export const {
  useGetApiAdminQuestionsActiveQuery,
  useGetApiAdminQuestionsScoredQuery,
  useGetApiAdminQuestionsDeactivatedQuery,
  useGetApiAdminQuestionsScoredByIdPostEstimationDownloadMutation,
  useGetApiAdminQuestionsRequestsQuery,
  usePostApiAdminQuestionsRequestsByIdApprovalMutation,
  usePostApiAdminQuestionsRequestsByIdDeclineMutation,
  usePostApiAdminQuestionsMutation,
  usePostApiAdminQuestionsUploadsXlsxMutation,
  useGetApiAdminOrganizationsQuery,
  usePostApiAdminOrganizationsMutation,
  useGetApiAdminOrganizationsRequestsQuery,
  useGetApiAdminOrganizationsRequestsUnreadCountQuery,
  usePatchApiAdminOrganizationsRequestsUnreadMutation,
  useGetApiAdminOrganizationsRequestsByIdQuery,
  useDeleteApiAdminOrganizationsRequestsByIdMutation,
  useGetApiAdminOrganizationsByIdQuery,
  usePutApiAdminOrganizationsByIdMutation,
  usePutApiAdminOrganizationsByIdQuestionsRequestsAutoApprovalMutation,
  usePutApiAdminGroupsByIdMutation,
  useGetApiAdminGroupsByIdQuery,
  usePatchApiAdminOrganizationsRequestsByIdAcceptMutation,
  usePatchApiAdminOrganizationsRequestsByIdDeclineMutation,
  useGetApiAdminGroupsQuery,
} = injectedRtkApi;
