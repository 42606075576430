import React, {
  FC, useEffect, useMemo
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetAdminQuestionsRequestsQuery
} from 'src/redux/openapi';
import {
  EmptyResultsMessage,
  Spinner,
  TableContent,
  TableFooter,
  usePaginationSettings,
} from 'src/shared/components';
import {
  DeclineSetRequestModal,
  ManageSetRequestModal,
} from 'src/features/Modals';

import {
  EditMenuActions
} from '../../types';
import {
  ManagementTableRow
} from '../ManagementTableRow';

import {
  getSetRequestsCols
} from './utils';

interface SetManagementContentProps {
  searchQuery: string;
  onAction: (id: string, action: EditMenuActions) => void;
  removeAction: () => void;
  currentAction: EditMenuActions | null;
  idToModify: string | null;
}

export const SetManagementContent: FC<SetManagementContentProps> = ({
  searchQuery,
  onAction,
  removeAction,
  currentAction,
  idToModify,
}) => {
  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  const {
    data, isLoading
  } = useGetAdminQuestionsRequestsQuery(
    {
      limit: pageSize,
      page: currentPage,
      query: searchQuery,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total, requests
  } = useMemo(
    () => data?.data || {
      requests: [],
      total: 0,
    },
    [data],
  );

  useEffect(
    () => {
      if (!requests.length && currentPage !== 1) {
        setCurrentPage((prev) => prev - 1);
      }
    },
    [requests.length]
  );

  const {
    i18n: {
      language
    },
    t,
  } = useTranslation();

  const setRequestsColumns = useMemo(
    () => getSetRequestsCols(language),
    [language],
  );

  const label = t('common.requests');

  const requestToModify = requests.find((request) => request.id === idToModify);

  const onRowClick = (id: string) => onAction(
    id,
    EditMenuActions.ManageSetRequest
  );

  const onDecline = () => onAction(
    idToModify || '',
    EditMenuActions.DeclineSetRequest
  );

  if (isLoading) {
    return <Spinner withLoadingText />;
  }

  if (!requests.length) {
    return (
      <EmptyResultsMessage
        results={label.toLowerCase()}
        withSearchQuery={!!searchQuery}
      />
    );
  }

  return (
    <>
      <TableContent
        data={requests}
        columns={setRequestsColumns}
        renderRow={(row, index) => (
          <ManagementTableRow
            row={row}
            key={row.original.id}
            index={index}
            onRowClick={() => onRowClick(row.original.id)}
          />
        )}
      />

      <TableFooter
        totalCount={total}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={label}
      />

      <ManageSetRequestModal
        id={idToModify || ''}
        isOpen={currentAction === EditMenuActions.ManageSetRequest}
        closeModal={removeAction}
        setTitle={requestToModify?.setTitle || ''}
        organisationName={requestToModify?.organization.name || ''}
        setStartDate={requestToModify?.setStartDate || ''}
        setEndDate={requestToModify?.setEndDate || ''}
        fileUrl={requestToModify?.xlsxUrl || ''}
        onDecline={onDecline}
      />

      <DeclineSetRequestModal
        isOpen={currentAction === EditMenuActions.DeclineSetRequest}
        closeModal={removeAction}
        id={idToModify || ''}
      />
    </>
  );
};
