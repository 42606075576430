import React, {
  FC
} from 'react';

import {
  CircleButton, type ButtonProps
} from 'src/shared/components';
import {
  themeColors
} from 'src/shared/utils/themeColors';
import {
  EditPencilIcon
} from 'src/shared/icons';

interface EditButtonProps extends ButtonProps {
  iconColor?: string;
  borderColor?: string;
  iconSize?: string;
  size?: number;
  onClick: () => void;
  withBorder?: boolean;
}

export const EditButton: FC<EditButtonProps> = ({
  iconColor = themeColors['dim-gray'],
  borderColor = themeColors.gray90,
  iconSize = 24,
  size,
  onClick,
  ...props
}) => (
  <CircleButton
    size={size}
    borderColor={borderColor}
    onClick={onClick}
    {...props}
  >
    <EditPencilIcon
      color={iconColor}
      width={iconSize}
      height={iconSize}
    />
  </CircleButton>
);
