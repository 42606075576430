import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  clsx
} from 'clsx';

import {
  CheckBoxFalseIcon, CheckBoxTrueIcon
} from 'src/shared/icons';
import {
  Tooltip
} from 'src/shared/components';
import {
  formatDateRange
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';

import * as Style from '../EditQuestions.styles';
import {
  EditQuestionType
} from '../types';
import {
  checkDeactivated, getTooltipMessage
} from '../utils';

interface QuestionCardProps {
  question: EditQuestionType;
  isSelected: boolean;
  onQuestionCheck: (id: string) => void;
  index: number;
  isRemoving?: boolean;
}

export const QuestionCard: FC<QuestionCardProps> = ({
  question,
  isSelected,
  onQuestionCheck,
  index,
  isRemoving,
}) => {
  const {
    id,
    title,
    isMandatory = false,
    status,
    startDate,
    endDate,
  } = question;

  const isDeactivated = !isRemoving && checkDeactivated(question);
  const isUnavailable = !isDeactivated && status === 'UNAVAILABLE';

  const {
    t
  } = useTranslation();

  const language = useTypedSelector(languageSelectors.getLanguage);

  const isDisabled = isDeactivated || (isMandatory && isSelected) || (isMandatory && isRemoving);

  const isActive = !isDeactivated && !(isMandatory && isRemoving);

  const tooltipMessage = isDisabled
    ? getTooltipMessage({
      isMandatory,
      isDeactivated,
    })
    : null;

  return (
    <Style.TopicDataContainer $isActive={isActive}>
      <Style.QuestionNumberModal>{`${index + 1}.`}</Style.QuestionNumberModal>

      <Style.QuestionContentContainer>
        <button
          type="button"
          onClick={() => onQuestionCheck(id)}
          disabled={isDisabled}
          className="flex"
        >
          <Tooltip
            variant="darkGray"
            opacity={1}
            disabled={!tooltipMessage}
            message={
              <Style.TooltipMessage>{tooltipMessage}</Style.TooltipMessage>
            }
          >
            {isSelected ? (
              <CheckBoxTrueIcon
                className={clsx(
                  'w-6 h-6 text-gray7',
                  {
                    'text-grey-dark-40': isDisabled,
                  }
                )}
              />
            ) : (
              <CheckBoxFalseIcon
                className={clsx(
                  'w-6 h-6 text-gray7',
                  {
                    'text-grey-dark-40': isDisabled,
                  }
                )}
              />
            )}
          </Tooltip>
        </button>

        <Style.QuestionInfoContainer>
          <Style.QuestionInfoTitleWrapper>
            <p className="grow">{title}</p>
          </Style.QuestionInfoTitleWrapper>

          <Style.QuestionExternalInfo>
            <Style.CardQuestionDates>
              {formatDateRange({
                start: startDate,
                end: endDate,
                language,
              })}
            </Style.CardQuestionDates>

            {isUnavailable && (
              <Style.UpcomingContainer $small>
                {t('question.upcoming')}
              </Style.UpcomingContainer>
            )}
          </Style.QuestionExternalInfo>
        </Style.QuestionInfoContainer>
      </Style.QuestionContentContainer>
    </Style.TopicDataContainer>
  );
};
