import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import clsx from 'clsx';

import {
  CloseIcon
} from 'src/shared/icons';
import {
  QuestionCard
} from 'src/entities/Questions';
import {
  SliderBlock,
  InputTextArea,
  PrivateCommentCheckMark,
  Button,
  ModalContainer,
  Spinner,
  warningToast,
  successfulToast,
} from 'src/shared/components';
import {
  formatDateRange,
  getPercent,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  useGetQuestionQuery,
  usePostQuestionForecastsMutation,
} from 'src/redux/openapi';
import {
  useMediaQuery
} from 'src/shared/hooks';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';

import * as Style from './ModalUpdateForecast.styles';

interface ModalUpdateForecastProps {
  updatedCard: QuestionCard;
  closeModalHandler: () => void;
  isModalOpen: boolean;
  refetchQuestions: () => void;
}

export const ModalUpdateForecast: FC<ModalUpdateForecastProps> = ({
  updatedCard,
  closeModalHandler,
  isModalOpen,
  refetchQuestions,
}) => {
  const {
    t
  } = useTranslation();

  const [privateComment, setPrivateComment] = useState(false);
  const [comment, setComment] = useState('');

  const [values, setValues] = useState<number[]>([]);

  const [isLessThan100, setIsLessThan100] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    startDate, endDate, title, type, id
  } = updatedCard;

  const isMultiChoice = type === 'MULTIPLE';

  const {
    data: questionData,
    isLoading,
    refetch,
    isFetching,
  } = useGetQuestionQuery({
    id,
  });

  const [postNewForecast] = usePostQuestionForecastsMutation();

  const forecast = useMemo(
    () => questionData?.data.question.forecasts[0],
    [questionData],
  );

  const questionEstimates = useMemo(
    () => forecast?.estimates || [],
    [questionData],
  );

  const totalValues = values.reduce(
    (acc, val) => acc + val,
    0
  );

  const isBigger = totalValues > 100;
  const isLess = isMultiChoice && totalValues < 100;

  const onSubmit = async () => {
    if (!forecast || isSubmitting) {
      return;
    }

    if (isBigger) {
      warningToast(t('errors.totalMoreThan100'));

      return;
    }

    if (isLess) {
      warningToast(t('errors.totalLessThan100'));
      setIsLessThan100(true);

      return;
    }

    try {
      setIsSubmitting(true);

      const estimates = questionEstimates.map((estimate, index) => ({
        ...estimate,
        value: values[index],
      }));

      const {
        message
      } = await postNewForecast({
        questionId: id,
        createForecastBodySchema: {
          estimates,
          comment: {
            text: comment,
            isPrivate: privateComment,
          },
        },
      }).unwrap();

      successfulToast(message);

      if (comment) {
        setComment('');
      }

      refetchQuestions();
      closeModalHandler();
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const setInitialEstimates = useCallback(
    () => {
      if (questionEstimates) {
        const estimateValues = questionEstimates.map(({
          value
        }) => value);

        setValues(estimateValues);
      }
    },
    [questionEstimates]
  );

  useEffect(
    () => {
      setInitialEstimates();
    },
    [setInitialEstimates]
  );

  useEffect(
    () => {
      refetch();
    },
    [isModalOpen]
  );

  const handleSetComment: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e,
  ) => {
    setComment(e.target.value);
  };

  const handlerPrivateCommentChange = () => {
    setPrivateComment(!privateComment);
  };

  const setNewValArr = (index: number) => {
    return (value: number) => setValues((prev) => prev.map((val, i) => {
      if (i === index) {
        return value;
      }

      return val;
    }),);
  };

  const equalValues = (num: number) => {
    setValues((prev) => prev.map((val) => val + num));
  };

  const equalRestValues = (rest: number) => {
    setValues((prev) => prev.map((val, i) => {
      return rest >= i + 1 ? val + 1 : val;
    }),);
  };

  const subtractEqual = () => {
    const newValues = values.map((val) => getPercent(
      val,
      totalValues
    ));

    const totalNewValues = newValues.reduce(
      (acc, val) => acc + val,
      0
    );

    setValues(newValues);

    if (totalNewValues < 100) {
      equalRestValues(100 - totalNewValues);
    }
  };

  const distributeEqually = () => {
    const rest = 100 - totalValues;

    if (rest > 0 && values.length > 0) {
      const part = Math.floor(rest / values.length);

      const addRest = part > 0 ? rest % part : rest;

      equalValues(part);
      equalRestValues(addRest);
    } else {
      subtractEqual();
    }
  };

  const isCountError = isBigger || (isLessThan100 && isLess);

  const language = useTypedSelector(languageSelectors.getLanguage);

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  return (
    <ModalContainer
      isOpen={isModalOpen}
      closeModalHandler={closeModalHandler}
      isHeader={false}
      modalWidth={isMobile ? 375 : 687}
      fullHeight={isMobile}
    >
      <Style.HeaderContainer>
        <div className="mb-10">
          <Style.ModalDateInfo>
            {formatDateRange({
              start: startDate,
              end: endDate,
              language,
            })}
          </Style.ModalDateInfo>

          <Style.ModalTitle>{title}</Style.ModalTitle>
        </div>

        <div className="self-end md:self-start">
          <Style.ButtonContainer
            onClick={closeModalHandler}
            type="button"
          >
            <CloseIcon className="text-dark-gray" />
          </Style.ButtonContainer>
        </div>
      </Style.HeaderContainer>

      <div className="mb-10">
        <Style.ModalSubTitle>
          {t('modal.updateYourForecast')}
        </Style.ModalSubTitle>

        {isLoading || isFetching ? (
          <Spinner size={24} />
        ) : (
          <div>
            {questionEstimates.length && (
              <>
                {isMultiChoice
                  && questionEstimates.map((val, i) => (
                    <SliderBlock
                      key={`${val.title}`}
                      text={val.title}
                      variant="small"
                      value={values[i]}
                      setValue={setNewValArr(i)}
                      formattedValue={`${values[i]}%`}
                    />
                  ))}

                {!isMultiChoice && (
                  <SliderBlock
                    variant="small"
                    value={values[0]}
                    setValue={setNewValArr(0)}
                    formattedValue={`${values[0]}%`}
                    defaultText
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>

      <div>
        <Style.TitlesContainer $isMultiChoice={!!isMultiChoice}>
          <Style.ModalSubTitle>
            {t('comment.updateYourComment')}
          </Style.ModalSubTitle>

          {isMultiChoice && (
            <Style.TotalValueBlock $isBigger={isCountError}>
              {isCountError && (
                <Style.TextBlock>{t('question.correctTo100')}</Style.TextBlock>
              )}

              {`${
                isCountError ? t('question.total') : t('question.yourTotal')
              }: ${totalValues}%`}
            </Style.TotalValueBlock>
          )}
        </Style.TitlesContainer>

        <div>
          <InputTextArea
            className="w-full pl-4 mb-2 py-17px"
            value={comment}
            onChange={handleSetComment}
          />

          <Style.DataContainer $isMultiChoice={!!isMultiChoice || isMobile}>
            <PrivateCommentCheckMark
              isPrivateComment={privateComment}
              changeIsPrivateComment={handlerPrivateCommentChange}
              className="mb-0"
            />

            <Style.FooterButtonsContainer
              $isMultiChoice={!!isMultiChoice || isMobile}
            >
              {isMobile ? (
                <Button
                  variant="big-grey-bordered"
                  onClick={setInitialEstimates}
                >
                  <Style.ModalResetSpan>
                    {t('buttons.reset')}
                  </Style.ModalResetSpan>
                </Button>
              ) : (
                <div className="flex gap-4">
                  <Button
                    variant="big-grey-bordered"
                    className={clsx(!isMobile && 'w-max px-6 py-3')}
                    onClick={setInitialEstimates}
                  >
                    <Style.ModalResetSpan>
                      {t('buttons.reset')}
                    </Style.ModalResetSpan>
                  </Button>

                  {isMultiChoice && (
                    <Button
                      type="button"
                      variant="big-grey-bordered"
                      className="px-6 py-3 w-max"
                      onClick={distributeEqually}
                    >
                      <Style.ModalEqualSpan>
                        {t('buttons.equallyDistribute')}
                      </Style.ModalEqualSpan>
                    </Button>
                  )}
                </div>
              )}

              <Button
                variant="big-blue"
                className={clsx(!isMobile && 'w-max')}
                onClick={onSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner
                    color="white"
                    size={24}
                  />
                ) : (
                  <Style.ModalSaveSpan>
                    {isMobile
                      ? t('buttons.update')
                      : t('question.updateForecast')}
                  </Style.ModalSaveSpan>
                )}
              </Button>
            </Style.FooterButtonsContainer>
          </Style.DataContainer>
        </div>
      </div>
    </ModalContainer>
  );
};
