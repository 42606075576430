import {
  emptySplitApi as api
} from '../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiNotifications: build.query<
    GetApiNotificationsApiResponse,
    GetApiNotificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
    }),
    getApiNotificationsUnread: build.query<
    GetApiNotificationsUnreadApiResponse,
    GetApiNotificationsUnreadApiArg
    >({
      query: () => ({
        url: `/api/notifications/unread`,
      }),
    }),
    patchApiNotificationsByIdStatus: build.mutation<
    PatchApiNotificationsByIdStatusApiResponse,
    PatchApiNotificationsByIdStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/${queryArg.id}/status`,
        method: 'PATCH',
        body: queryArg.updateNotificationStatusBodySchema,
      }),
    }),
    getApiNotificationsByIdGroupsStatistics: build.query<
    GetApiNotificationsByIdGroupsStatisticsApiResponse,
    GetApiNotificationsByIdGroupsStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/${queryArg.id}/groups/statistics`,
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiNotifications
};
export type GetApiNotificationsApiResponse =
  /** status 200 Default Response */ NotificationsResponseSchema;
export type GetApiNotificationsApiArg = {
  page?: number;
  limit?: number;
  filters?: {
    from?: string | null;
    sortBy?: 'createdAt';
    sortOrder?: 'asc' | 'desc';
    status?: 'UNREAD' | 'READ';
    actionRequired?: boolean;
    type?:
    | 'GROUP_INVITATION'
    | 'COMMENT_REPLY'
    | 'GROUP_USER_PROMOTION'
    | 'GROUP_USER_REMOVAL'
    | 'GROUP_ORGANIZATION_REQUEST'
    | 'IMMUTABLE_CHARACTERISTIC_UPDATE'
    | 'CHARACTERISTIC_CHANGE_ACCEPTED'
    | 'CHARACTERISTIC_CHANGE_REJECTED'
    | 'ORGANIZATION_ADMIN_MESSAGE'
    | 'QUESTION_CREATE_REQUEST_DECLINE'
    | 'QUESTION_CREATE_REQUEST_APPROVE';
    tab?: 'replies' | 'groups' | 'actions';
    query?: string;
  };
};
export type GetApiNotificationsUnreadApiResponse =
  /** status 200 Default Response */ UnreadNotificationsCountResponseSchema;
export type GetApiNotificationsUnreadApiArg = void;
export type PatchApiNotificationsByIdStatusApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiNotificationsByIdStatusApiArg = {
  id: string;
  updateNotificationStatusBodySchema: UpdateNotificationStatusBodySchema;
};
export type GetApiNotificationsByIdGroupsStatisticsApiResponse =
  /** status 200 Default Response */ GroupStatisticsByNotificationResponseSchema;
export type GetApiNotificationsByIdGroupsStatisticsApiArg = {
  id: string;
};
export type NotificationsResponseSchema = {
  message: string;
  data: {
    notifications: {
      id: string;
      createdAt: string;
      updatedAt: string;
      type:
      | 'GROUP_INVITATION'
      | 'COMMENT_REPLY'
      | 'GROUP_USER_PROMOTION'
      | 'GROUP_USER_REMOVAL'
      | 'GROUP_ORGANIZATION_REQUEST'
      | 'IMMUTABLE_CHARACTERISTIC_UPDATE'
      | 'CHARACTERISTIC_CHANGE_ACCEPTED'
      | 'CHARACTERISTIC_CHANGE_REJECTED'
      | 'ORGANIZATION_ADMIN_MESSAGE'
      | 'QUESTION_CREATE_REQUEST_DECLINE'
      | 'QUESTION_CREATE_REQUEST_APPROVE';
      status: 'UNREAD' | 'READ';
      context:
      | (
        | (
          | (
            | (
              | (
                | (
                  | (
                    | {
                      groupId: string;
                      groupName: string;
                      invitationMessage: string | null;
                      status:
                      | 'PENDING'
                      | 'ACCEPTED'
                      | 'REJECTED';
                      fromUserId: string;
                      fromUserName: string;
                    }
                    | {
                      groupId: string;
                      groupName: string;
                      organizationName: string;
                      organizationId?: string;
                      status:
                      | 'PENDING'
                      | 'ACCEPTED'
                      | 'REJECTED';
                    }
                  )
                  | {
                    groupId: string;
                    groupName: string;
                    promotionUserId: string;
                    promotionUserName: string;
                  }
                )
                | {
                  groupId: string;
                  groupName: string;
                  suggestUserId: string;
                  suggestUserName: string;
                  reason: string | null;
                }
              )
              | {
                userId: string;
                userName: string;
                associationId: string;
                suggestUserId?: string;
                status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
                characteristics: {
                  keyId: string;
                  keyName: string;
                  newValueId: string;
                  newValue: string;
                  prevValueId: string | null;
                  prevValue: string | null;
                }[];
              }
            )
            | {
              organizationName: string;
              organizationId: string;
              message: string;
            }
          )
          | {
            message: string | null;
            organizationId: string;
          }
        )
        | {
          organizationId: string;
        }
      )
      | null;
      group: {
        id: string;
        name: string;
        location: string | null;
      } | null;
      invitation: {
        invitationMessage: string | null;
        invitationExpiration: string | null;
      } | null;
      inviteFromUser: {
        id: string;
        avatarUrl: string | null;
        name: string;
        displayName: string | null;
      } | null;
      suggestUser: {
        id: string;
        avatarUrl: string | null;
        name: string;
        displayName: string | null;
      } | null;
      organization: {
        id: string;
        name: string;
        isSetUp: boolean;
        logoUrl: string | null;
      } | null;
      replyComment: {
        id: string;
        rootCommentId: string;
        createdAt: string;
        text: string;
        authorId: string | null;
        authorName: string | null;
        authorAvatarUrl: string | null;
        questionId: string | null;
      } | null;
    }[];
    total: number;
    unreadCount: {
      all: number;
      reply: number;
      groups: number;
      actions: number;
    };
  };
};
export type UnreadNotificationsCountResponseSchema = {
  message: string;
  data: {
    unreadTotal: number;
  };
};
export type MessageResponseSchema = {
  message: string;
};
export type UpdateNotificationStatusBodySchema = {
  status: 'UNREAD' | 'READ';
};
export type GroupStatisticsByNotificationResponseSchema = {
  message: string;
  data: {
    group: {
      id: string;
      name: string;
      location: string | null;
      updatedAt: string;
      createdAt: string;
      memberCount: number;
      forecastCount: number | null;
      commentCount: number | null;
      questionSets: {
        id: string;
        title: string;
        startDate: string | null;
        endDate: string | null;
      }[];
    };
  };
};
export const {
  useGetApiNotificationsQuery,
  useGetApiNotificationsUnreadQuery,
  usePatchApiNotificationsByIdStatusMutation,
  useGetApiNotificationsByIdGroupsStatisticsQuery,
} = injectedRtkApi;
