import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useNavigate
} from 'react-router-dom';

import {
  TableFooter,
  TableContent,
  TableRow,
  Spinner,
  EmptyResultsMessage,
  SwitchButton,
  listViewSwitchOptions,
} from 'src/shared/components';
import {
  useGetOrganizationGroupsQuery
} from 'src/redux/openapi';
import {
  useListView
} from 'src/shared/hooks';
import {
  ROUTE
} from 'src/shared/utils';
import {
  usePaginationSettings
} from 'src/shared/components/Pagination';

import {
  GroupOrgCard
} from '../GroupOrgCard';

import {
  getGroupCols
} from './utils';
import * as Style from './GroupTabContent.styles';

interface GroupsTabContentProps {
  searchQuery: string;
  organizationId: string;
}

export const GroupsTabContent: FC<GroupsTabContentProps> = ({
  searchQuery,
  organizationId,
}) => {
  const {
    onSwitch, isTableView
  } = useListView();

  const {
    currentPage, setCurrentPage, pageSize, onPageSizeChange
  } = usePaginationSettings(searchQuery);

  const groupColumns = getGroupCols();

  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  const onRowClick = (groupId: string) => navigate(`/${ROUTE.GROUPS}/${groupId}`);

  const {
    data: groupsData, isLoading
  } = useGetOrganizationGroupsQuery(
    {
      id: organizationId,
      query: searchQuery,
      limit: pageSize,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups: currentGroups
  } = groupsData?.data || {
    total: 0,
    groups: [],
  };

  return (
    <>
      {isLoading ? (
        <Spinner size={24} />
      ) : (
        <>
          <Style.SwitchButtonContainer>
            <SwitchButton
              options={listViewSwitchOptions}
              onChange={onSwitch}
              defaultOption={
                isTableView
                  ? listViewSwitchOptions[0]
                  : listViewSwitchOptions[1]
              }
            />
          </Style.SwitchButtonContainer>

          {!!currentGroups.length
            && (isTableView ? (
              <TableContent
                data={currentGroups}
                columns={groupColumns}
                renderRow={(row, index) => (
                  <TableRow
                    row={row}
                    rowKey="id"
                    key={row.original.id}
                    index={index}
                    variant="white"
                    onRowClick={onRowClick}
                    canClick
                  />
                )}
              />
            ) : (
              <Style.GroupCards className="scroll-hidden">
                {currentGroups.map((group) => (
                  <GroupOrgCard
                    group={group}
                    key={group.id}
                  />
                ))}
              </Style.GroupCards>
            ))}

          {!currentGroups.length && (
            <EmptyResultsMessage
              results={t('common.groups').toLowerCase()}
              withSearchQuery={!!searchQuery}
            />
          )}
        </>
      )}

      <TableFooter
        totalCount={totalGroups}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={t('common.groups')}
      />
    </>
  );
};
