import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useSearchParams
} from 'react-router-dom';

import {
  EmptyResultsMessage,
  Spinner,
  TableContent,
  TableFooter,
  TableRow,
  TableTabs,
} from 'src/shared/components';
import {
  ManagementActiveQuestion,
  ManagementDeactivatedQuestion,
} from 'src/redux/openapi';
import {
  questionTab
} from 'src/shared/utils';
import {
  SetQuestionScoreModal
} from 'src/features';

import {
  getQuestionColumns,
  getTabsMap,
  QuestionScoringTabs,
  QuestionToScore,
} from './utils';

interface ScoringTableContentProps {
  data: ManagementActiveQuestion[] | ManagementDeactivatedQuestion[];
  pageSize: number;
  onPageSizeChange: (value: number) => void;
  setCurrentPage: (value: number) => void;
  currentPage: number;
  total: number;
  organizationId?: string;
  isLoading?: boolean;
  withSearchQuery?: boolean;
}

export const ScoringTableContent: FC<ScoringTableContentProps> = ({
  data,
  pageSize,
  onPageSizeChange,
  setCurrentPage,
  currentPage,
  total,
  organizationId,
  isLoading,
  withSearchQuery,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [questionToScore, setQuestionToScore] = useState<QuestionToScore | null>(null);

  const {
    i18n: {
      language
    },
    t,
  } = useTranslation();

  const tabs = getTabsMap();

  const currentTab = searchParams.get(questionTab) || QuestionScoringTabs.Active;

  const columns = getQuestionColumns(
    language,
    setQuestionToScore
  );

  const onTabChange = (tabId: string) => {
    searchParams.set(
      questionTab,
      tabId
    );

    setSearchParams(searchParams);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="border-b border-b-gray-whisper px-3">
        <TableTabs
          tabs={tabs}
          currentTab={currentTab}
          onTabChange={onTabChange}
        />
      </div>

      {data.length ? (
        <TableContent
          columns={columns}
          data={data}
          renderRow={(row) => (
            <TableRow
              row={row}
              variant="white"
            />
          )}
        />
      ) : (
        <>
          {isLoading && <Spinner withLoadingText />}

          {!isLoading && (
            <EmptyResultsMessage
              results={t('common.questions').toLowerCase()}
              withSearchQuery={withSearchQuery}
            />
          )}
        </>
      )}

      <TableFooter
        totalCount={total}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        selectorLabel={t('common.questions')}
      />

      <SetQuestionScoreModal
        isOpen={!!questionToScore?.id}
        closeModal={() => setQuestionToScore(null)}
        questionId={questionToScore?.id || null}
        organizationId={organizationId}
        hidePicker={currentTab === QuestionScoringTabs.Deactivated}
        startDate={questionToScore?.startDate}
        endDate={questionToScore?.endDate}
      />
    </>
  );
};
