import React, {
  FC, useState
} from 'react';
import {
  useLocation, useNavigate, useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  EditButton,
  Table,
  TableContentWrapper,
  TableHeader,
  TableSearch,
  TableTabs,
} from 'src/shared/components';
import {
  organisationTab, ROUTE
} from 'src/shared/utils';
import {
  useDebouncedValue
} from 'src/shared/hooks';
import {
  UserGroupRole, Organization
} from 'src/redux/openapi';
import {
  CommunitySection,
  QuestionsFilter,
  CommunityActions,
} from 'src/widgets';
import {
  ScoringFilters
} from 'src/widgets/QuestionScoring';

import {
  OrganisationTabs, getTabsMap
} from './utils';
import {
  CommunityContent,
  GroupsTabContent,
  QuestionScoringContent,
  SetsQuestionsContent,
} from './ui';

interface OrganisationTableProps {
  organization: Organization;
}

export const OrganisationTable: FC<OrganisationTableProps> = ({
  organization,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');

  const [communityAction, setCommunityAction] = useState<CommunityActions | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    id, role
  } = organization;

  const currentTab = searchParams.get(organisationTab) || OrganisationTabs.SetsAndQuestions;

  const onTabChange = (tabId: string) => {
    searchParams.set(
      organisationTab,
      tabId
    );

    setSearchParams(searchParams);
    setSearchQuery('');
  };

  const debouncedQuery = useDebouncedValue(
    searchQuery,
    500
  );

  const tabs = getTabsMap();

  const {
    t
  } = useTranslation();

  const toggleQuestionsEditing = () => {
    navigate(`${location.pathname}/${ROUTE.EDIT_QUESTIONS}`);
  };

  const isAdmin = role !== UserGroupRole.USER;

  return (
    <TableContentWrapper>
      <TableHeader>
        <TableTabs
          tabs={tabs}
          currentTab={currentTab}
          onTabChange={onTabChange}
        />

        <div className="flex gap-3 items-center justify-end">
          <TableSearch
            searchQuery={searchQuery}
            placeholder={t('common.startTyping')}
            handleSearch={setSearchQuery}
          />

          {currentTab === OrganisationTabs.SetsAndQuestions && (
            <>
              <QuestionsFilter withBorder={false} />

              {isAdmin && (
                <EditButton
                  onClick={toggleQuestionsEditing}
                  withBorder={false}
                />
              )}
            </>
          )}

          {currentTab === OrganisationTabs.QuestionScoring && (
            <ScoringFilters />
          )}

          {currentTab === OrganisationTabs.Community && isAdmin && (
            <CommunitySection.CommunityMoreBtn
              setActionType={setCommunityAction}
              organizationId={id}
            />
          )}
        </div>
      </TableHeader>

      <Table>
        {currentTab === OrganisationTabs.Groups && (
          <GroupsTabContent
            organizationId={id}
            searchQuery={debouncedQuery}
          />
        )}

        {currentTab === OrganisationTabs.SetsAndQuestions && (
          <SetsQuestionsContent
            organizationId={id}
            role={role}
            searchQuery={debouncedQuery}
          />
        )}

        {currentTab === OrganisationTabs.Community && (
          <CommunityContent
            organization={organization}
            searchQuery={debouncedQuery}
            setSearchQuery={setSearchQuery}
          />
        )}

        {currentTab === OrganisationTabs.QuestionScoring && (
          <QuestionScoringContent
            organizationId={id}
            searchQuery={debouncedQuery}
          />
        )}
      </Table>

      <CommunitySection.ModalSendMsgToMembers
        isOpen={communityAction === CommunityActions.SendMessages}
        closeModal={() => setCommunityAction(null)}
      />
    </TableContentWrapper>
  );
};
