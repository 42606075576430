import React, {
  FC, MouseEvent
} from 'react';

import {
  Divider,
  MenuContainer,
  MenuItem,
  PopoverMenu,
} from 'src/shared/components';
import {
  MoreVerticalIcon
} from 'src/shared/icons';
import {
  themeColors
} from 'src/shared/utils';
import {
  ToggleAutoApproval
} from 'src/features/ToggleAutoApproval';

import {
  ActionHandler, EditMenuActions, ManagementEntity
} from '../../types';

import * as Style from './EditMenu.styles';
import {
  getEditMenuConfig
} from './utils';

interface EditMenuProps {
  onAction: ActionHandler;
  type: ManagementEntity;
  id: string;
}

export const EditMenu: FC<EditMenuProps> = ({
  onAction, type, id
}) => {
  const menuConfig = getEditMenuConfig(type);

  const onMenuClick = (
    event: MouseEvent<HTMLButtonElement>,
    toggleCallback: () => void,
  ) => {
    event.stopPropagation();

    toggleCallback();
  };

  const onActionClick = (
    event: MouseEvent<HTMLButtonElement>,
    action: EditMenuActions,
  ) => {
    event.stopPropagation();

    if (action === EditMenuActions.ToggleAutoApproval) {
      return;
    }

    onAction(
      id,
      action
    );
  };

  return (
    <PopoverMenu
      placement="bottom-end"
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <Style.EditButton
          type="button"
          onClick={(event) => onMenuClick(
            event,
            toggleMenu
          )}
          {...triggerProps}
          $isOpen={isOpen}
        >
          <MoreVerticalIcon className="w-6 h-6" />
        </Style.EditButton>
      )}
    >
      <MenuContainer $width={294}>
        {menuConfig.map(({
          action, label, icon: Icon
        }, index) => {
          const isDelete = [
            EditMenuActions.DeleteOrg,
            EditMenuActions.DeleteRequest,
            EditMenuActions.DeleteGroup,
          ].includes(action);

          return (
            <React.Fragment key={action}>
              {isDelete && !!index && <Divider />}

              <MenuItem
                key={action}
                $color={
                  isDelete
                    ? themeColors['red-text-error']
                    : themeColors['dark-gray']
                }
                onClick={(event) => onActionClick(
                  event,
                  action
                )}
              >
                <Icon />

                {label}

                {action === EditMenuActions.ToggleAutoApproval && (
                  <ToggleAutoApproval organizationId={id} />
                )}
              </MenuItem>
            </React.Fragment>
          );
        })}
      </MenuContainer>
    </PopoverMenu>
  );
};
