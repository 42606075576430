import {
  t
} from 'i18next';

export enum OrganisationTabs {
  SetsAndQuestions = 'setsAndQuestions',
  Community = 'community',
  Groups = 'groups',
  QuestionScoring = 'questionScoring',
}

export const getTabsMap = () => [
  {
    id: OrganisationTabs.SetsAndQuestions,
    label: t('question.setsAndQuestions'),
  },
  {
    id: OrganisationTabs.Community,
    label: t('organisation.community'),
  },
  {
    id: OrganisationTabs.Groups,
    label: t('group.Groups'),
  },
  {
    id: OrganisationTabs.QuestionScoring,
    label: t('question.questionScoring'),
  },
];
