import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';
import {
  Link
} from 'react-router-dom';

import {
  ManagementActiveQuestion,
  ManagementDeactivatedQuestion,
} from 'src/redux/openapi';
import {
  formatDateRange, numberWithPeriods, ROUTE
} from 'src/shared/utils';
import {
  Button, GrayText
} from 'src/shared/components';
import {
  HeaderRow, StatisticTile
} from 'src/entities/Questions';

import {
  DownloadExPostDebasing
} from '../Buttons';

export enum Filters {
  ArqQuestions = 'arqQuestions',
  OrgQuestions = 'organisationQuestions',
  WithFeedback = 'withFeedback',
}

export enum QuestionScoringTabs {
  Active = 'active',
  Deactivated = 'deactivated',
  Scored = 'scored',
}

export const getFiltersMap = (
  isAdmin: boolean,
  currentTab: QuestionScoringTabs,
) => {
  const isScoredTab = currentTab === QuestionScoringTabs.Scored;

  if (!isAdmin && !isScoredTab) {
    return null;
  }

  const adminFilters = [
    {
      id: Filters.ArqQuestions,
      label: t('management.arqQuestions'),
    },
    {
      id: Filters.OrgQuestions,
      label: t('management.orgQuestions'),
    },
  ];

  if (!isScoredTab) {
    return adminFilters;
  }

  const scoredFilters = [
    {
      id: Filters.WithFeedback,
      label: t('management.withFeedback'),
    },
  ];

  if (!isAdmin) {
    return scoredFilters;
  }

  return [...adminFilters, ...scoredFilters];
};

export const getTabsMap = () => [
  {
    id: QuestionScoringTabs.Active,
    label: t('common.active'),
  },
  {
    id: QuestionScoringTabs.Deactivated,
    label: t('common.deactivated'),
  },
  {
    id: QuestionScoringTabs.Scored,
    label: t('common.scored'),
  },
];

const activeColumnsHelper = createColumnHelper<
ManagementActiveQuestion | ManagementDeactivatedQuestion
>();

const checkResult = (
  question: ManagementActiveQuestion | ManagementDeactivatedQuestion,
): question is ManagementDeactivatedQuestion => {
  return 'result' in question && question.result !== null;
};

export type QuestionToScore = Pick<
ManagementActiveQuestion,
'id' | 'startDate' | 'endDate'
>;

export const getQuestionColumns = (
  language: string,
  setQuestionToScore: (question: QuestionToScore) => void,
) => [
  activeColumnsHelper.accessor(
    'title',
    {
      header: t('modal.groupName'),
      footer: (props) => props.column.id,
      size: 400,
      minSize: 400,
      cell: ({
        row: {
          original: question
        }
      }) => {
        const isResult = checkResult(question);

        return (
          <Link
            className="flex flex-col gap-1 px-2 py-1 group"
            to={`/${ROUTE.QUESTION}/${question.id}`}
          >
            <HeaderRow
              question={question.questionSet.title}
              date={formatDateRange({
                start: question.startDate,
                end: question.endDate,
                language,
              })}
              isSurvey={question.type === 'SURVEY'}
              isTable
            />

            <p className="font-bold text-17-26 text-gray7 group-hover:text-dim-gray transition-all">
              {question.title}
            </p>

            {isResult && (
            <p className="font-medium text-xs text-dim-gray">
              {question.result ? t('buttons.yes') : t('buttons.no')}
            </p>
            )}
          </Link>
        );
      },
    }
  ),
  activeColumnsHelper.accessor(
    'globalEstimatesAvg',
    {
      header: t('common.forecasts'),
      footer: (props) => props.column.id,
      maxSize: 180,
      size: 150,
      minSize: 120,
      cell: ({
        row: {
          original: {
            globalEstimatesAvg, globalEstimatesChange
          },
        },
      }) => (
        <StatisticTile
          amount={globalEstimatesAvg}
          icon={null}
          growth={globalEstimatesChange || null}
          className="!p-1 max-h-min"
        />
      ),
    }
  ),
  activeColumnsHelper.accessor(
    'participantsCount',
    {
      header: t('common.participants'),
      footer: (props) => props.column.id,
      maxSize: 180,
      size: 150,
      minSize: 120,
      cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
    }
  ),
  activeColumnsHelper.accessor(
    'commentsCount',
    {
      header: t('common.comments'),
      footer: (props) => props.column.id,
      maxSize: 180,
      size: 150,
      minSize: 120,
      cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
    }
  ),
  activeColumnsHelper.accessor(
    'updatesCount',
    {
      header: t('common.updates'),
      footer: (props) => props.column.id,
      maxSize: 180,
      size: 150,
      minSize: 120,
      cell: (info) => <GrayText>{numberWithPeriods(info.getValue())}</GrayText>,
    }
  ),
  activeColumnsHelper.accessor(
    'id',
    {
      header: '',
      footer: (props) => props.column.id,
      size: 165,
      minSize: 165,
      cell: ({
        row: {
          original: question
        }
      }) => {
        const isResult = checkResult(question);

        const {
          organization, id, startDate, endDate
        } = question;

        if (isResult) {
          return (
            <div className="flex justify-end pr-3">
              <DownloadExPostDebasing
                organisationId={organization?.id}
                questionId={id}
                hasFeedback={question.hasFeedbacks}
              />
            </div>
          );
        }

        return (
          <Button
            variant="big-blue"
            className="!py-2 !px-4 !text-15-20 font-bold !shrink-0 !h-max !w-max"
            onClick={() => setQuestionToScore({
              id,
              startDate,
              endDate,
            })}
          >
            {t('question.scoreTheQuestion')}
          </Button>
        );
      },
    }
  ),
];
