import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const TableContainer = styled.table<{ $applyMinWidth?: boolean }>`
  ${tw`w-full table-fixed`}

  ${({
    $applyMinWidth
  }) => $applyMinWidth && tw`min-w-1016px`}
`;

export const TableHead = styled.thead`
  ${tw`
    border-b border-gray-whisper h-52px
    text-xs text-dark-gray font-medium sticky top-0 bg-white
  `}
`;

export const TableRow = styled.tr`
  ${tw`border-b border-gray-whisper last:border-b-0`}
`;

export const Title = styled.a`
  ${tw`text-gray7 font-bold truncate mt-1 cursor-pointer block`}
`;
export const TitleSpan = styled.span`
  ${tw`hover:(text-dim-gray bg-dashed-hover bg-bottom bg-repeat-x bg-[length:8px_1px])
  active:(text-gray7 bg-dashed-active bg-bottom bg-repeat-x bg-[length:8px_1px])
  transition-colors`}
`;

export const TextCell = styled.p<{ $underlined?: boolean }>`
  ${tw`text-center text-dim-gray text-17-26 font-medium`}

  ${({
    $underlined
  }) => $underlined
    && tw`underline decoration-button-blue decoration-dotted underline-offset-6`}
`;

export const TruncatedCell = styled.td`
  ${tw`truncate px-3 py-4`}
`;

export const ButtonCell = styled.td`
  ${tw`px-3 py-4`}
`;

export const OnboardText = styled.div`
  ${tw`text-xs leading-5 font-medium text-dim-gray mt-1`}
`;

export const ButtonCellRow = styled.div`
  ${tw`ml-auto w-max`}
`;

export const ColumnHeader = styled.th<{ $width: number }>`
  ${({
    $width
  }) => `
    width: ${$width}%;
  `}
`;

export const EmptyQuestionsMessage = styled.p`
  ${tw`text-17 font-medium text-dim-gray h-full flex justify-center items-center`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-2 items-center justify-center px-3`}
`;
